export const getCompanyLogos = () => [
  {
    imgSrc: 'creuna.svg',
    url: 'https://www.creuna.com/dk/'
  },
  {
    imgSrc: 'timeadvice.png',
    url: 'http://www.timeadvice.dk/'
  },
  {
    imgSrc: 'Combine.svg',
    url: 'https://combine.dk/'
  },
  {
    imgSrc: 'tia.svg',
    url: 'https://tiatechnology.com/'
  },
  {
    imgSrc: 'usertribe.png',
    url: 'https://usertribe.com/'
  },
  {
    imgSrc: 'rohde.svg',
    url: 'https://www.rohde-schwarz.com/dk/home_48230.html'
  },
  {
    imgSrc: 'kamstrup.svg',
    url: 'https://www.kamstrup.com/da-dk'
  },
  {
    imgSrc: 'stabil-it.png',
    url: 'http://stabil-it.dk/forside'
  },
  {
    imgSrc: 'itminds.png',
    url: 'https://it-minds.dk/'
  },
  {
    imgSrc: 'flexdanmark.png',
    url: 'https://www.flexdanmark.dk/'
  }
];

export const getCompanyLogos2020 = () => [
  {
    imgSrc: 'Dynaway.svg',
    url: 'https://www.dynaway.com/'
  },
  {
    imgSrc: 'FunCenter.svg',
    url: 'https://www.funcenter.dk/'
  },
  {
    imgSrc: 'Kl.svg',
    url: 'https://www.kl.dk/'
  },
  {
    imgSrc: 'Tia.svg',
    url: 'https://tiatechnology.com/'
  },
  {
    imgSrc: 'Mjolner.svg',
    url: 'https://mjolner.dk/'
  },
  {
    imgSrc: 'Sommerlandsjaelland.svg',
    url: 'https://sommerlandsj.dk/'
  },
  {
    imgSrc: 'Translyft.svg',
    url: 'https://translyft.com/da/'
  }
];
